.topbtn button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: rgb(22, 45, 80);
    background-color: transparent;
    padding: auto;
    border-style: none;
    cursor: pointer;
}

.topbtn button:hover {
    color: #2f5ba7;
}