.current-page {
    background-color: #2424248f;
    border-radius: 30px;
    position: fixed;
    font-size: 2rem;
    padding: 2%;
    color: rgb(224, 224, 224);
    bottom: 25px;
}

.images-viewer-container .fa-solid {
    position: fixed;
    font-size: 4rem;
    padding: 5px;
    /* color: rgb(22, 45, 80); */
    color: rgb(224, 224, 224);
    cursor: pointer;
    background-color: #2424248f;
    border-radius: 15px;
}

.fa-chevron-left {
    top: calc(100vh / 2);
    left: 25px;
}

.fa-chevron-right {
    top: calc(100vh / 2);
    right: 25px;
}

.images-viewer-container img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}

.images-viewer-container video {
    width: 80%;
    height: auto;
    max-height: 75%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}

.images-viewer-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224, 224, 224);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    overflow: hidden;
}