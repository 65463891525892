.folders-item {
    text-align: center;
}

.parent-gallery-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.images-parent {
    width: 28%;
    margin: 2%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 5%;
    cursor: pointer;
}

@media screen and (max-width: 580px) {
    .images-parent {
        width: 45%;
    }
}


@media screen and (max-width: 362px) {
    .images-parent {
        width: 80%;
    }
}