* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #afdde9;
}

::-webkit-scrollbar-thumb {
  background-color: #162d50;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2f5ba7;
}
.navbar {
  background-image: url("/images/navbarv2.png");
  background-repeat: repeat-x;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 30%;
  z-index: 999;
}
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    position: absolute;
    margin: 20px;
    width: auto;
    max-width: 100%;
  }
  
  .navbar-logo {
    color: #000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .navbar-logo img {
    height: 60px;
    margin-bottom: 7%;
    width: auto;
  }
  
  .small img {
    width: 0%;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    /* margin-right: 2rem; */
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: rgb(22, 45, 80);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color: #2f5ba7;
    border-bottom: 4px solid #2f5ba7;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: rgb(22, 45, 80);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  } 
  
  /* @media screen and (max-width: 1200px) {
    .nav-links {
      font-size: 18px;
    }
  } */
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .navbar-container {
      position: unset;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: white;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: rgb(22, 45, 80);
      color: rgb(224, 224, 224);
      border-radius: 0;
    }
  
    .navbar-logo img {
      /* width: 15vh; */
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(15%, 15%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: rgb(22, 45, 80);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: rgb(22, 45, 80);
      padding: 14px 20px;
      border: 1px solid rgb(22, 45, 80);
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: rgb(22, 45, 80);
      color: #242424;
      transition: 250ms;
    }
  }

  @media screen and (max-width: 362px) {
    .small img {
      height: 60px;
      width: auto;
    }

    .big img{
      width: 0%;
    }
  }
.footer-container {
  background-image: url("/svg/footerv2.svg");
  background-repeat: repeat-x;
  background-size: contain;
  height: 60px;
}

.copy h5 {
    color: rgb(22, 45, 80);
    padding: 40px 0 0 20px;
}

.copy a {
    text-decoration: none;
    color: rgb(22, 45, 80);
}

.copy a:hover {
    color: #2f5ba7;
}

@media  (max-width: 583px) {
    .footer-container {
        background-size: cover;
    }
}
.hero-container {
    background: url('/photos/gallery/thumb/IMG_1396.JPG') center center/cover no-repeat, #afdde9;
    height: 90vh;
    min-height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    
}

.zapisy-text {
    background-size: cover;
    padding: 1%;
    border-radius: 25px;
    background-color: rgba(77, 199, 215, 0.2);
}

a{
    color:#162d50;
    text-decoration: none;
}
a:hover{
    color:#2f5ba7;
}
button{
    background-color: #162d50;
    color:white;
    font-size: 15px;
    width: 50%;
    height: 2.5em;;
    border-radius: 25px;
    border: none;
}
button:hover{
    background-color: #2f5ba7;
    border: none;
    cursor: pointer;
}
.text{
    padding:1%;
    border-radius: 25px;
  background-color: rgba(22, 45, 80, 0.4);
  background-size: cover;
  margin-top: 90px;
}

.text > h1 {
    text-align: center;
    color: #fff;
    font-size: 100px;
    text-shadow: 2px 2px #121a31;
    
}

.text > p {
    text-align: center;
    color: #fff;
    font-size: 45px;
    margin-top: 8px;
    text-shadow: 2px 2px #121a31;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.items-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
.item{
    vertical-align: top;
    text-align: center;
    margin:1%;
    padding:1%;
    background-color: white;
    display: inline-block;
    border-radius: 25px;
 width: 20%;
    color: #162d50;
}
.item img{
    width: 20%;
}

@media screen and (max-width: 1175px) {

    
    .hero-container {
        min-height: 660px;
    }  
    .items-container h1 {
        font-size: 26px;
    }
    
    .items-container h4 {
        font-size: 14px;
    }

    .items-container h3 {
        font-size: 18px;
    }
}

@media screen and (max-width: 1080px) {

    .text > h1 {
        font-size: 70px;
    }

    .text {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1040px) {
    .hero-container {
        min-height: 550px;
    }  
    .item {
        width: 29%;
    }
}


@media screen and (max-width: 768px) {
    .hero-container {
        height: 75vh;
        min-height: 515px;
    }   

    /* .text {
        margin-top: -100px;
    } */

    .text > h1 {
        font-size: 50px;
    }

    .text > p {
        font-size: 30px;
    }
}

@media screen and (max-width: 700px) {
    .hero-container {
        min-height: 450px;
    }  

    .item {
        width: 50%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .item img {
        width: 14%;
    }
    .items-container h1 {
        font-size: 22px;
    }
    .items-container h4 {
        font-size: 12px;
    }
    .items-container h3 {
        font-size: 14px;
    }
}

@media screen and (max-width: 510px) {
    .hero-container {
        height: 85vh;
        min-height: 700px;
    }   

    .text {
        margin-top: -150px;
    }

    .text > h1 {
        font-size: 35px;
    }

    .text > p {
        font-size: 25px;
    }
    
    .items-container {
        flex-direction: column;
        height: 40%;
        justify-content: unset;
    }
}

@media screen and (max-width: 368px) {
    .hero-container {
        height: 80vh;
        min-height: 570px;
    }   

    /* .text {
        margin-top: -100px;
    } */

    .text> h1 {
        font-size: 25px;
    }

    .text > p {
        font-size: 20px;
    }
    .items-container h1 {
        font-size: 18px;
    }
    .items-container h4 {
        font-size: 10px;
    }
    .items-container h3 {
        font-size: 12px;
    }
}
.about-container{
background-color: #afdde9;
align-items: center;
justify-content: center;
flex-direction: column;
display: flex;
}
.main{
    display: inline-block;
    margin:1%;
    border-radius: 25px;
    padding: 3%;
    background-color: white;
    color: #162d50;
    width:60%;
}
.main h1{
    text-align: center;
}
.main-mini{
    border-radius: 25px;
    vertical-align: top;
    font-size: 14px;
    width: 18%;
    margin:1%;
    padding: 1%;
}
.main-mini .fa-solid{
    font-size: 40px;
    margin-bottom: 5%;
}
.main img{
    width: 45%;
    margin:1%;
    padding: 3%;
}
.main-img{
text-align: center;
align-items: center;
justify-content: center;
display: flex;
}
ul{
    margin:1%;
    padding: 1%;
}

.sun-img{
    display: inline-block;
}
.sun-img{
    width: 10%;
}
.main-podzial{
    background-color: transparent;
flex-direction: row;
display: flex;
align-items: center;
justify-content: center;
}
.kafelek{
    align-items: center;
justify-content: center;
display: flex;
height: 400px;
}
.kafelek.tlo{
    color: #162d50;
    background-image: url('/cloud.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align:bottom;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    border-radius: 25px;
    padding:1%;
    
}
.kafelek.tlo>h2{
    padding: 2%;
    margin:1%;  
    font-size: 22px;
    text-align: center;
    width: 60%;
    text-shadow: 2px 2px #afdde9;
}
.kafelek.tlo.stack{
margin-left: -5%;
}

@media screen and (max-width: 1440px) {
    .kafelek.tlo>h2{
        font-size: 16px;
    }
}

@media screen and (max-width: 820px) {
    .main {
        width: 80%;
    }

    .main h1 {
        font-size: 26px;
    }

    .main h2 {
        font-size: 20px;
    }

    .main h3 {
        font-size: 16px;
    }
    .kafelek.tlo > h2{
        font-size: 13px;
    }
    .main-mini{
        width: 160%;
        padding: 2%;
    }
}

@media screen and (max-width: 600px) {
    .main-podzial img {
        align-self: center;
    }

    .main-podzial {
        /* width: 100%; */
        display: flex;
        flex-direction: column;
    }

    .main h1 {
        font-size: 24px;
    }

    .main h2 {
        font-size: 18px;
    }

    .main h3 {
        font-size: 14px;
    }

    .main-img {
        flex-direction: column;
    }

    .main-mini {
        margin: 2%; width: 80%; padding: 2%;
    }


    .kafelek{
        height: 200px;
    }

    .kafelek.tlo>h2{
        font-size: 16px;
    }
    .kafelek.tlo {
        height: 40vh;
        margin-top: -10%;
        margin-bottom: -10%;
    }
}



@media screen and (max-width: 362px) {
    .opac.sun {
        height: 15vh;
    }
    .main h1 {
        font-size: 16px;
    }

    .main h2 {
        font-size: 10px;
    }

    .main h3 {
        font-size: 10px;
    }

    .main-podzial.over {
        padding-left: 4%;
    }
    .main-mini .fa-solid{
        font-size: 25px;
    }
    .main-mini {
        padding: 2%;
       font-size: 10px;
       margin:1%;
    }
}

.news-container{
    background-color: #afdde9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.items{
    font-family: "Walter Turncoat", cursive;
    margin-top: 1%;
    justify-content: center;
    flex-direction: row;
    display: flex;
    vertical-align: top;
    width: 70%;
    border-radius: 15px;
    border-width: 10px;
    border-style:solid;
    border-color: #52371d;
    background-image: url('/chalk-board.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1%;
    margin:1%;
}
.items >hr{
    border: none solid white;
    background-color: white;
}
.aktualnosci{
    margin:1%;
    vertical-align: top;
    text-align: center;
    width: 50%;
    padding:2%;
    font-size: 22px;
    color:white;
    text-shadow: 2px 2px rgb(70, 70, 70);
    border-radius: 25px;
    display: inline-block;
}
.aktualnosci button{
    font-size: 15px;
    color:white;
}
.strefa button{
    font-size: 15px;
    color:white;
}
.items h1 {font-family: 'Chalk', sans-serif;
}

.items h3 {font-family: 'Chalk', sans-serif;
}

.table-column {
    opacity: 0;
    height: 0px;
}

.table-row {
    text-align: -webkit-center;
}

.strefa{
    margin:1%;
    text-align: center;
    vertical-align: top;
    width: 40%;
    padding:2%;
    border-radius: 25px;
    display: inline-block;
    
    font-size: 22px;
    color:white;
    text-shadow: 2px 2px rgb(70, 70, 70);
}
.strefa img{
    width:10%;
    vertical-align: bottom;
}

.extra {
    color: #162d50;
    width: 65%;
    border-radius: 15px;
    border-width: 10px;
    border-style:solid;
    border-color: #996633;
    background-color: #c5aa86;
    padding: 1%;
    margin:1%;
}

.extra table {
    width: 100%;
}

.extra td >  h3 {
    margin-top: 50%;
}

.extra td {
    width: 20%;
    padding: 2%;
    background-image: url('/note.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.extra th {
    background-color: white;
    padding: 2%;
    border-radius: 25px;
 
}

.plan{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    background-color: white;
    padding:3%;
    border-radius: 25px;
    width: 65%;
    display: inline-block;
}
.plan>h1{
    text-align: center;
}
.plan>h3{
    padding: 1%;
}
.dot {
    height: 30px;
    width: 25px;
    background-color: #afdde9;
    border-radius: 50%;
    display: block;
    margin-top: 100%;

  }
  .dots{
    vertical-align: top;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: inline-block;
    width: 10%;
  }
  .lines{
    margin:2%;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: inline-block;
    width: 80%;
    
    margin-top: 10%;
  }
  .lines > hr{
   border-top: 1px solid lightslategray;
  }
  .lines >h3{
    font-family: 'Tillana', cursive;
  }

  @media screen and (max-width: 880px) {
    .items{
        width: 90%;
    }
    .extra{
        width: 90%;
    }
    .plan{
        width: 90%;
    }
    .extra th > h1 {
        font-size: 24px;
    }
    .extra th > h2 {
        font-size: 20px;
    }
    .extra td > h3 {
        font-size: 16px;
    }
    .extra td > h4 {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 680px) {
    .extra td > h3 {
        font-size: 12px;
    }
    .extra td > h4 {
        font-size: 10px;
    }
    .extra h4 {
        font-size: 10px;
    }
  }

  @media screen and (max-width: 580px) {
    .items {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .items > hr {
        align-self: stretch;
    }

    .aktualnosci, .strefa {
        width: 100%;
    }

    .plan > h1 {
        font-size: 24px;
    }

    .lines > h3 {
        font-size: 16px;
    }

    .table-row {
        opacity: 0;
        height: 0px;
    }

    .table-column {
        opacity: 1;
        height: auto;
    }

    .extra td {
        background-size: contain;
        height: 80px;
    }

    .extra {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .extra hr {
        width: 35%;
    }

    .column-item >  h3 {
        margin-top: 15%;
    }

    .column-item >  h4 {
        margin-bottom: 10%;
    }

    .extra th > h1 {
        font-size: 16px;
    }

    .extra th > h2 {
        font-size: 12px;
    }

    .header-table {
        width: 85%;
        display: flex;
        flex-direction: column;
    }

    .header-table-row {
        text-align: -webkit-center;
    }
  }
.downloads>h3{
    margin-bottom: 10px;
}

.downloads>h4{
    margin-top: 5px;
}

.downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ogloszenia-container{
    background-color: #afdde9;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    width: 80%;
}

.ogloszenia {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.ogloszenia-items{
    color: #162d50;
    /* vertical-align: top; */
    margin:2%;
    padding:4%;
    background-color: white;
    border-radius: 25px;
    text-align: left;
    /* display: inline-block; */
}

.ogloszenia-items>h3{
    text-align: left;
}


.pliki{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    /* background-color: white; */
    padding:3%;
    width: 100%;
    display: inline-block;
}
.kalendarz{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    background-color: white;
    padding:3%;
    width: 100%;
    justify-content: center;
}
.kalendarz>h3{
    margin-bottom: 10%;
}
.pasek{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    padding: 1%;
    border-radius: 25px;
    background-color: white;
    vertical-align: top;
    justify-content: top;
    width: 20%;
}


@media screen and (max-width: 1080px) {
    .ogloszenia-container{
        flex-direction: column;
        align-items: center;
    }

    
    .ogloszenia {
        width: 70%;
    }

    .pasek {
        width: 70%;
    }

    .downloads > h3, h4{
        width: 100%;
    }

    .kalendarz {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .kalendarz > h3 {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .kalendarz > div {
        width: 100%;
    }

    .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width: 706px) {
    .ogloszenia-items>h1{
        font-size: 24px;
    }

    .ogloszenia-items>h3{
        font-size: 16px;
    }

    .ogloszenia-items>h4{
        font-size: 12px;
    }
}

@media screen and (max-width: 580px) {
    .ogloszenia {
        width: 80%;
    }

    .pasek {
        width: 80%;
    }
  }

  @media screen and (max-width: 362px) {
    .ogloszenia {
        width: 90%;
    }

    .pasek {
        width: 90%;
    }
  }
.gallery-container {
    background-color: #afdde9;
    padding: 12px 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.gallery-container .images {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.images img {
    width: auto;
    max-width: 45vw;
    min-width: 18vh;
    max-height: 30vh;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224, 224, 224);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model.open .fa-times{
    position: fixed;
    top: 25px;
    right: 50px;
    font-size: 2rem;
    padding: 5px;
    color: rgb(22, 45, 80);
    cursor: pointer;
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}
.topbtn button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: rgb(22, 45, 80);
    background-color: transparent;
    padding: auto;
    border-style: none;
    cursor: pointer;
}

.topbtn button:hover {
    color: #2f5ba7;
}
.strefa-container{
background-color: #afdde9;
align-items: center;
justify-content: center;
flex-direction: column;
display: flex;
min-height: calc(100vh - 160px);
}

.navbar-logo {
  width: 20vh;
  cursor: auto;
}

.top-text {
  display: flex;
  flex-direction: row;
}

.password-input {
  align-items: center;
}

.strefa-rodzic{
    display: inline-block;
    margin:1%;
    border-radius: 25px;
    padding: 10%;
    background-color: white;
    color: #162d50;
    width:60%;
}

.input-field {
    width: 78%;
    padding: 15px;
    border-radius: 25px;
    margin: 15px 0 15px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
  }
  .input-eye{
    display: inline;
    position: relative;
  }
  .fa-eye{
    position: absolute;
    right: 2%;
    top: calc(50% - 0.5em)
  }
  .fa-eye-slash{
    position: absolute;
    right: 2%;
    top: calc(50% - 0.5em)
  }

  .button-strefa{
    margin:1%;
width: 20%;
  }

  @media screen and (max-width: 880px) {
    .strefa-text > h1 {
      font-size: 24px;
    }

    .strefa-text > h3 {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 760px) {
    .strefa-rodzic {
      padding: 5%;
    }

    .top-text {
      flex-direction: column;
      align-items: center;
    }

    .strefa-text {
      text-align: center;
    }

    .navbar-logo {
      margin: 0;
    }

    .password-input {
      text-align: center;
    }

    .input-field {
      width: 100%;
    }

    .button-strefa {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 580px) {
    .strefa-rodzic {
      width: 80%;
    }
  }

  @media screen and (max-width: 362px) {
    .strefa-rodzic {
      width: 90%;
    }
  }
.folders-container{
    background-color: #afdde9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.folders-div > h1{
    text-align: center;
}
.folders-div{

    margin:1%;
    border-radius: 25px;
    padding: 3%;
    background-color: white;
    color: #162d50;
    width:60%;
}
img{
    width: 100%;
}
.folder-text{
    display: inline-block;
    padding: 1%;
    text-align: center;
    width: 20%;
}

.parent-hr {
    margin: 2% 0% 4%;
}

@media screen and (max-width: 960px) {
    .folders-div {
        width: 80%;
    }
}

@media screen and (max-width: 580px) {
    .folders-div > h1 {
        font-size: 24px;
    }

    .folders-item > h1 {
        font-size: 20px;
    }
}
.current-page {
    background-color: #2424248f;
    border-radius: 30px;
    position: fixed;
    font-size: 2rem;
    padding: 2%;
    color: rgb(224, 224, 224);
    bottom: 25px;
}

.images-viewer-container .fa-solid {
    position: fixed;
    font-size: 4rem;
    padding: 5px;
    /* color: rgb(22, 45, 80); */
    color: rgb(224, 224, 224);
    cursor: pointer;
    background-color: #2424248f;
    border-radius: 15px;
}

.fa-chevron-left {
    top: calc(100vh / 2);
    left: 25px;
}

.fa-chevron-right {
    top: calc(100vh / 2);
    right: 25px;
}

.images-viewer-container img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}

.images-viewer-container video {
    width: 80%;
    height: auto;
    max-height: 75%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}

.images-viewer-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224, 224, 224);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    overflow: hidden;
}
.folders-item {
    text-align: center;
}

.parent-gallery-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.images-parent {
    width: 28%;
    margin: 2%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 5%;
    cursor: pointer;
}

@media screen and (max-width: 580px) {
    .images-parent {
        width: 45%;
    }
}


@media screen and (max-width: 362px) {
    .images-parent {
        width: 80%;
    }
}
