.gallery-container {
    background-color: #afdde9;
    padding: 12px 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.gallery-container .images {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.images img {
    width: auto;
    max-width: 45vw;
    min-width: 18vh;
    max-height: 30vh;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224, 224, 224);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model.open .fa-times{
    position: fixed;
    top: 25px;
    right: 50px;
    font-size: 2rem;
    padding: 5px;
    color: rgb(22, 45, 80);
    cursor: pointer;
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 30px;
    margin: 0 auto;
}