
.news-container{
    background-color: #afdde9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.items{
    font-family: "Walter Turncoat", cursive;
    margin-top: 1%;
    justify-content: center;
    flex-direction: row;
    display: flex;
    vertical-align: top;
    width: 70%;
    border-radius: 15px;
    border-width: 10px;
    border-style:solid;
    border-color: #52371d;
    background-image: url('/chalk-board.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1%;
    margin:1%;
}
.items >hr{
    border: none solid white;
    background-color: white;
}
.aktualnosci{
    margin:1%;
    vertical-align: top;
    text-align: center;
    width: 50%;
    padding:2%;
    font-size: 22px;
    color:white;
    text-shadow: 2px 2px rgb(70, 70, 70);
    border-radius: 25px;
    display: inline-block;
}
.aktualnosci button{
    font-size: 15px;
    color:white;
}
.strefa button{
    font-size: 15px;
    color:white;
}
.items h1 {font-family: 'Chalk', sans-serif;
}

.items h3 {font-family: 'Chalk', sans-serif;
}

.table-column {
    opacity: 0;
    height: 0px;
}

.table-row {
    text-align: -webkit-center;
}

.strefa{
    margin:1%;
    text-align: center;
    vertical-align: top;
    width: 40%;
    padding:2%;
    border-radius: 25px;
    display: inline-block;
    
    font-size: 22px;
    color:white;
    text-shadow: 2px 2px rgb(70, 70, 70);
}
.strefa img{
    width:10%;
    vertical-align: bottom;
}

.extra {
    color: #162d50;
    width: 65%;
    border-radius: 15px;
    border-width: 10px;
    border-style:solid;
    border-color: #996633;
    background-color: #c5aa86;
    padding: 1%;
    margin:1%;
}

.extra table {
    width: 100%;
}

.extra td >  h3 {
    margin-top: 50%;
}

.extra td {
    width: 20%;
    padding: 2%;
    background-image: url('/note.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.extra th {
    background-color: white;
    padding: 2%;
    border-radius: 25px;
 
}

.plan{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    background-color: white;
    padding:3%;
    border-radius: 25px;
    width: 65%;
    display: inline-block;
}
.plan>h1{
    text-align: center;
}
.plan>h3{
    padding: 1%;
}
.dot {
    height: 30px;
    width: 25px;
    background-color: #afdde9;
    border-radius: 50%;
    display: block;
    margin-top: 100%;

  }
  .dots{
    vertical-align: top;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: inline-block;
    width: 10%;
  }
  .lines{
    margin:2%;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: inline-block;
    width: 80%;
    
    margin-top: 10%;
  }
  .lines > hr{
   border-top: 1px solid lightslategray;
  }
  .lines >h3{
    font-family: 'Tillana', cursive;
  }

  @media screen and (max-width: 880px) {
    .items{
        width: 90%;
    }
    .extra{
        width: 90%;
    }
    .plan{
        width: 90%;
    }
    .extra th > h1 {
        font-size: 24px;
    }
    .extra th > h2 {
        font-size: 20px;
    }
    .extra td > h3 {
        font-size: 16px;
    }
    .extra td > h4 {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 680px) {
    .extra td > h3 {
        font-size: 12px;
    }
    .extra td > h4 {
        font-size: 10px;
    }
    .extra h4 {
        font-size: 10px;
    }
  }

  @media screen and (max-width: 580px) {
    .items {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .items > hr {
        align-self: stretch;
    }

    .aktualnosci, .strefa {
        width: 100%;
    }

    .plan > h1 {
        font-size: 24px;
    }

    .lines > h3 {
        font-size: 16px;
    }

    .table-row {
        opacity: 0;
        height: 0px;
    }

    .table-column {
        opacity: 1;
        height: auto;
    }

    .extra td {
        background-size: contain;
        height: 80px;
    }

    .extra {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .extra hr {
        width: 35%;
    }

    .column-item >  h3 {
        margin-top: 15%;
    }

    .column-item >  h4 {
        margin-bottom: 10%;
    }

    .extra th > h1 {
        font-size: 16px;
    }

    .extra th > h2 {
        font-size: 12px;
    }

    .header-table {
        width: 85%;
        display: flex;
        flex-direction: column;
    }

    .header-table-row {
        text-align: -webkit-center;
    }
  }