.folders-container{
    background-color: #afdde9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.folders-div > h1{
    text-align: center;
}
.folders-div{

    margin:1%;
    border-radius: 25px;
    padding: 3%;
    background-color: white;
    color: #162d50;
    width:60%;
}
img{
    width: 100%;
}
.folder-text{
    display: inline-block;
    padding: 1%;
    text-align: center;
    width: 20%;
}

.parent-hr {
    margin: 2% 0% 4%;
}

@media screen and (max-width: 960px) {
    .folders-div {
        width: 80%;
    }
}

@media screen and (max-width: 580px) {
    .folders-div > h1 {
        font-size: 24px;
    }

    .folders-item > h1 {
        font-size: 20px;
    }
}