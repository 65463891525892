.ogloszenia-container{
    background-color: #afdde9;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    width: 80%;
}

.ogloszenia {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.ogloszenia-items{
    color: #162d50;
    /* vertical-align: top; */
    margin:2%;
    padding:4%;
    background-color: white;
    border-radius: 25px;
    text-align: left;
    /* display: inline-block; */
}

.ogloszenia-items>h3{
    text-align: left;
}


.pliki{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    /* background-color: white; */
    padding:3%;
    width: 100%;
    display: inline-block;
}
.kalendarz{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    background-color: white;
    padding:3%;
    width: 100%;
    justify-content: center;
}
.kalendarz>h3{
    margin-bottom: 10%;
}
.pasek{
    color: #162d50;
    vertical-align: top;
    margin:1%;
    padding: 1%;
    border-radius: 25px;
    background-color: white;
    vertical-align: top;
    justify-content: top;
    width: 20%;
}


@media screen and (max-width: 1080px) {
    .ogloszenia-container{
        flex-direction: column;
        align-items: center;
    }

    
    .ogloszenia {
        width: 70%;
    }

    .pasek {
        width: 70%;
    }

    .downloads > h3, h4{
        width: 100%;
    }

    .kalendarz {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .kalendarz > h3 {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .kalendarz > div {
        width: 100%;
    }

    .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width: 706px) {
    .ogloszenia-items>h1{
        font-size: 24px;
    }

    .ogloszenia-items>h3{
        font-size: 16px;
    }

    .ogloszenia-items>h4{
        font-size: 12px;
    }
}

@media screen and (max-width: 580px) {
    .ogloszenia {
        width: 80%;
    }

    .pasek {
        width: 80%;
    }
  }

  @media screen and (max-width: 362px) {
    .ogloszenia {
        width: 90%;
    }

    .pasek {
        width: 90%;
    }
  }