.navbar {
  background-image: url("/images/navbarv2.png");
  background-repeat: repeat-x;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 30%;
  z-index: 999;
}
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    position: absolute;
    margin: 20px;
    width: auto;
    max-width: 100%;
  }
  
  .navbar-logo {
    color: #000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .navbar-logo img {
    height: 60px;
    margin-bottom: 7%;
    width: auto;
  }
  
  .small img {
    width: 0%;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    /* margin-right: 2rem; */
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: rgb(22, 45, 80);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color: #2f5ba7;
    border-bottom: 4px solid #2f5ba7;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: rgb(22, 45, 80);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  } 
  
  /* @media screen and (max-width: 1200px) {
    .nav-links {
      font-size: 18px;
    }
  } */
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .navbar-container {
      position: unset;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: white;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: rgb(22, 45, 80);
      color: rgb(224, 224, 224);
      border-radius: 0;
    }
  
    .navbar-logo img {
      /* width: 15vh; */
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(15%, 15%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: rgb(22, 45, 80);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: rgb(22, 45, 80);
      padding: 14px 20px;
      border: 1px solid rgb(22, 45, 80);
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: rgb(22, 45, 80);
      color: #242424;
      transition: 250ms;
    }
  }

  @media screen and (max-width: 362px) {
    .small img {
      height: 60px;
      width: auto;
    }

    .big img{
      width: 0%;
    }
  }