.downloads>h3{
    margin-bottom: 10px;
}

.downloads>h4{
    margin-top: 5px;
}

.downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
